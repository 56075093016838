import ScrollReveal from 'scrollreveal'
export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    //desktop animations config
    var desktop = {
      mobile: false,
      distance: '5%',
      duration: 1500,
      viewFactor: 0.3,
      delay: 100,
      origin: 'top',
    }

    ScrollReveal(desktop);

    ScrollReveal().reveal('.hero-content, .footer .container');

    ScrollReveal().reveal('.strapline', {
      origin: 'bottom',
      distance: '10%',
      viewFactor: 0,
      delay: 200,
    });

    ScrollReveal().reveal('#why-choose .row, #why-choose .header, .testimonials-panel .header', {
      origin: 'bottom',
    });


    ScrollReveal().reveal('.service:nth-of-type(odd) .content-col', {
      origin: 'left',
    });

    ScrollReveal().reveal('.service:nth-of-type(even) .content-col', {
      origin: 'right',
    });

    ScrollReveal().reveal('.prefooter-panel .hero-content', {
      origin: 'bottom',
    });
  },
};
